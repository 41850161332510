var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{staticClass:"top-row-section py-2"},[(_vm.modalShow)?_c('IodReportPopup',{attrs:{"modalShow":_vm.modalShow,"lensOrderId":_vm.lensOrderId},on:{"update:modalShow":function($event){_vm.modalShow=$event},"update:modal-show":function($event){_vm.modalShow=$event}}}):_vm._e(),_c('b-row',{staticClass:"mb-4"},[_c('b-col',{staticClass:"mt-2"},[_c('h5',{staticClass:"heavy d-inline mr-1"},[_vm._v(_vm._s(_vm.t('reserve')))]),_c('span',{staticClass:"text-gray-dark"},[_vm._v(" "+_vm._s(_vm.t('viewingAllReserves'))+" ")])])],1),_c('b-table',{ref:"reserveTableContainer",staticClass:"no-outer-padding reserve-card-table",attrs:{"fields":_vm.fields,"items":_vm.reservedLenses,"sticky-header":""},scopedSlots:_vm._u([{key:"table-colgroup",fn:function(scope){return _vm._l((scope.fields),function(field){return _c('col',{key:field.key,style:({width: _vm.colWidths[field.key]})})})}},{key:"cell(updated)",fn:function(data){return [_vm._v(" "+_vm._s(_vm._f("date")(data.value))+" ")]}},{key:"cell(ocosSalesOrderId)",fn:function(ref){
var value = ref.value;
var item = ref.item;
return [_c('custom-router-link',{staticClass:"record-link mb-1",attrs:{"to":{
                    name: 'ReserveDetails',
                    params: {orderId: item.orderHeaderId.toString()},
                }}},[_vm._v(" "+_vm._s(value)+" ")])]}},{key:"cell(targetLensDescription)",fn:function(ref){
                var item = ref.item;
                var value = ref.value;
return [_c('p',{staticClass:"text-nowrap text-caption heavy text-black mb-1 pl-0 ml-0"},[_c('b-img',{staticClass:"pr-1",attrs:{"src":_vm.imageSrc(item.opEye)}}),_vm._v(" "+_vm._s(_vm.decimalSeparatorFormatter(value.model, _vm.decimalSeparator))+" ")],1),_c('p',{staticClass:"text-caption light text-gray-dark mb-0"},[_vm._v(" "+_vm._s(_vm.lensDescriptionObjToString(value, _vm.decimalSeparator))+" ")])]}},{key:"cell(reservedLensDescription)",fn:function(ref){
                var item = ref.item;
                var value = ref.value;
return [_c('LensModel',{attrs:{"lensDescriptionDetails":value}}),_c('div',{staticClass:"d-flex"},[_c('LensPrescription',{attrs:{"lensDescriptionDetails":value}}),(_vm.isConsignment(item) && value.serial)?_c('span',{staticClass:"text-caption light text-gray-dark mb-0 white-space-pre"},[_vm._v(" #"+_vm._s(value.serial))]):_vm._e()],1)]}},{key:"cell(status)",fn:function(ref){
                var value = ref.value;
                var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getReservationOrderStatus(value, {isConsignment: _vm.isConsignment(item)}))),_c('br'),(
                    [
                        _vm.OrderSubStatus.RESERVED,
                        _vm.OrderSubStatus.IN_CART,
                        _vm.OrderSubStatus.CONSUMED ].includes(value)
                )?_c('p',{staticClass:"text-caption light text-gray-dark mb-0"},[(_vm.isConsignment(item))?_c('span',[_vm._v(" "+_vm._s(_vm.t('invLookup_Consignment'))+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm.t('short_expiration'))+" "+_vm._s(_vm._f("date")(item.expirationDate)))])]):_vm._e(),(value == _vm.OrderSubStatus.ORDERED)?_c('custom-router-link',{staticClass:"record-link text-primary-dark mb-1",attrs:{"to":{
                    name: 'OrderDetails',
                    params: {orderId: item.followUpOrderHeaderId},
                }}},[_vm._v(" "+_vm._s(!!item.orderNumber ? item.orderNumber : item.followUpOrderHeaderId)+" ")]):_vm._e()]}},{key:"cell(doctorName)",fn:function(ref){
                var value = ref.value;
                var item = ref.item;
return [_c('div',{staticClass:"d-flex align-items-start"},[_c('span',{staticClass:"text-caption heavy"},[_vm._v(" "+_vm._s(_vm.surgeonIdNameFormat(item.contactPartyNumber, value, item.doctorActive))+" ")])])]}},{key:"cell(actions)",fn:function(data){return [_c('div',{staticClass:"cell-w-buttons justify-content-end"},[_c('b-button',{attrs:{"variant":"outline-primary mr-2"},on:{"click":function($event){return _vm.detailsBtnClicked(data.item)}}},[_vm._v(" "+_vm._s(_vm.t('details')))]),(_vm.isConsignment(data.item))?_c('b-button',{attrs:{"variant":"outline-primary"},on:{"click":function($event){return _vm.iodBtnClicked(data.item)}}},[_vm._v(" "+_vm._s(_vm.isSidButton(data.item)))]):_vm._e()],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }